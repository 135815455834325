import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M2043 3803 c-44 -8 -22 -19 61 -30 170 -23 285 -113 411 -322 l38
-63 -16 -142 c-9 -77 -30 -260 -47 -406 -63 -553 -82 -706 -91 -730 -7 -19 -6
-35 5 -60 30 -69 33 -70 145 -70 102 0 136 13 60 23 -24 3 -50 15 -61 27 -26
28 -25 52 32 687 22 249 40 462 40 473 0 26 17 26 25 -1 4 -11 30 -70 59 -132
29 -62 76 -161 104 -222 27 -60 81 -177 120 -260 38 -82 118 -257 177 -387 60
-131 114 -238 121 -238 7 0 16 15 19 33 17 79 16 76 282 677 74 168 153 346
175 395 22 50 49 111 60 137 12 27 23 46 25 44 2 -2 15 -82 28 -177 13 -96 45
-325 70 -509 51 -371 64 -439 96 -526 57 -154 187 -315 301 -372 115 -58 283
-87 387 -66 41 8 40 24 -1 24 -58 0 -168 28 -225 57 -107 54 -209 166 -267
293 -50 110 -77 248 -181 910 -119 764 -113 730 -126 730 -10 0 -56 -95 -124
-250 -17 -41 -117 -264 -220 -495 -103 -231 -206 -460 -227 -510 -22 -49 -42
-93 -45 -98 -8 -13 -10 -9 -208 422 -95 207 -186 405 -203 441 -16 36 -58 126
-92 200 -108 235 -205 357 -343 434 -34 19 -89 42 -122 50 -55 15 -188 19
-242 9z"/>
<path d="M1820 2518 c1 -41 45 -78 93 -78 31 0 67 39 67 73 l0 27 -80 0 c-76
0 -80 -1 -80 -22z"/>
<path d="M2210 1990 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M827 1518 c-2 -13 -27 -74 -55 -135 -49 -109 -50 -113 -29 -113 17 0
25 8 32 33 l10 32 77 3 76 3 13 -36 c10 -27 19 -35 37 -35 13 0 21 3 19 8 -17
29 -117 246 -117 253 0 5 -13 9 -29 9 -22 0 -30 -5 -34 -22z m61 -83 c12 -25
22 -49 22 -53 0 -4 -21 -7 -47 -6 -35 1 -47 5 -45 15 5 25 35 89 42 89 3 0 16
-20 28 -45z"/>
<path d="M3637 1490 c-42 -13 -57 -29 -57 -61 0 -35 32 -59 81 -59 47 0 99
-18 99 -35 0 -36 -98 -53 -150 -25 -23 12 -26 11 -33 -16 -3 -9 23 -14 98 -21
51 -4 64 -2 88 17 54 43 27 94 -58 105 -85 11 -95 14 -95 35 0 28 35 43 86 35
24 -3 46 -10 49 -16 8 -12 25 -2 25 16 0 8 -15 17 -37 20 -21 4 -45 8 -53 10
-8 2 -28 -1 -43 -5z"/>
<path d="M1088 1380 l3 -110 82 0 c59 0 89 5 105 16 26 18 29 59 6 92 -8 12
-10 22 -5 22 6 0 11 16 11 35 0 46 -20 55 -122 55 l-83 0 3 -110z m153 80 c23
-13 25 -46 3 -54 -9 -3 -40 -6 -70 -6 -53 0 -54 1 -54 28 0 16 3 32 7 35 10
11 92 8 114 -3z m19 -105 c6 -8 9 -23 5 -35 -6 -18 -15 -20 -76 -20 l-69 0 0
35 0 35 64 0 c43 0 67 -5 76 -15z"/>
<path d="M1395 1380 l0 -110 85 0 c61 0 92 4 108 16 26 18 29 59 6 92 -8 12
-10 22 -5 22 6 0 11 16 11 35 0 46 -20 55 -122 55 l-83 0 0 -110z m159 77 c10
-7 17 -22 14 -33 -3 -16 -13 -19 -71 -22 l-67 -3 0 29 c0 16 3 32 7 35 12 12
99 7 117 -6z m20 -103 c4 -9 4 -25 1 -35 -5 -16 -17 -19 -76 -19 l-69 0 0 35
0 35 69 0 c55 0 70 -3 75 -16z"/>
<path d="M1760 1478 c0 -7 -20 -57 -44 -110 -38 -82 -42 -98 -27 -98 10 0 22
13 29 34 12 32 14 33 71 35 62 2 81 -9 84 -48 1 -15 8 -21 23 -21 22 0 21 4
-23 97 -26 54 -46 103 -46 110 0 8 -13 13 -34 13 -20 0 -33 -5 -33 -12z m57
-62 c12 -24 19 -47 16 -50 -10 -10 -83 -7 -83 4 0 17 32 90 39 90 4 0 17 -20
28 -44z"/>
<path d="M1995 1380 c0 -100 2 -110 18 -110 16 0 18 9 16 80 -1 44 0 83 3 85
7 7 138 -140 138 -154 0 -6 11 -11 24 -11 23 0 23 1 22 110 -1 90 -4 110 -16
110 -12 0 -14 -15 -12 -87 l3 -88 -65 70 c-36 39 -68 78 -71 88 -4 10 -17 17
-33 17 l-27 0 0 -110z"/>
<path d="M2338 1389 c3 -76 0 -104 -10 -110 -9 -6 -5 -9 12 -9 l25 0 -4 86
c-4 103 4 103 54 3 20 -38 39 -69 43 -69 4 0 25 35 47 78 l40 77 3 -87 c2 -75
5 -88 20 -88 15 0 17 13 17 110 l0 110 -27 0 c-16 0 -28 -5 -28 -12 0 -6 -16
-40 -36 -76 l-37 -65 -41 76 c-30 56 -46 77 -61 77 -19 0 -20 -6 -17 -101z"/>
<path d="M2743 1484 c6 -6 -55 -154 -79 -191 -14 -22 -13 -23 9 -23 17 0 25 8
32 33 10 32 10 32 75 32 64 0 64 0 74 -32 6 -24 15 -33 30 -33 20 0 18 6 -26
98 -26 53 -48 103 -48 110 0 7 -14 12 -37 12 -20 0 -34 -3 -30 -6z m57 -64
c11 -21 20 -44 20 -50 0 -11 -76 -14 -83 -2 -5 8 29 92 37 92 3 0 15 -18 26
-40z"/>
<path d="M2975 1380 l0 -110 88 0 c74 0 90 3 107 20 24 24 26 57 3 82 -13 14
-14 21 -5 30 17 17 15 54 -4 72 -12 12 -37 16 -102 16 l-87 0 0 -110z m156 80
c23 -13 25 -46 3 -54 -9 -3 -40 -6 -70 -6 l-54 0 0 35 0 35 51 0 c28 0 59 -5
70 -10z m14 -125 l0 -30 -67 -3 -68 -3 0 36 0 36 68 -3 67 -3 0 -30z"/>
<path d="M3300 1463 c-83 -75 -27 -193 92 -193 39 0 54 6 83 31 31 27 35 37
35 78 -1 75 -42 111 -129 111 -41 0 -56 -5 -81 -27z m156 -24 c52 -49 14 -140
-58 -142 -47 -1 -84 18 -98 53 -37 89 84 157 156 89z"/>
<path d="M3880 1406 c0 -112 13 -128 101 -134 54 -3 68 -1 91 17 25 20 27 27
30 111 3 80 1 90 -14 90 -16 0 -18 -11 -18 -84 0 -55 -4 -87 -12 -94 -26 -20
-75 -23 -113 -8 l-36 16 3 85 c3 76 1 85 -14 85 -16 0 -18 -11 -18 -84z"/>
<path d="M4210 1380 l0 -110 79 0 c44 0 91 4 105 10 34 12 43 52 21 86 -12 19
-14 29 -6 37 16 16 13 53 -5 71 -12 12 -37 16 -105 16 l-89 0 0 -110z m166 76
c15 -12 17 -18 8 -33 -8 -14 -26 -19 -73 -21 l-61 -3 0 36 0 35 53 0 c31 0 61
-6 73 -14z m9 -121 l0 -30 -67 -3 -68 -3 0 36 0 36 68 -3 67 -3 0 -30z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
